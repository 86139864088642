import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import { StorageKeys } from '../../enums/storage-keys';
import { AuthService } from '../../services/auth-service/auth.service';
import { LoaderService } from '../../services/loader-service/loader.service';
import { finalize } from 'rxjs/operators';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* TODO: refactor */
    const updateLoader = req.url.startsWith('http');
    if (updateLoader) {
      this.loaderService.show();
    }

    if (this.authService.isAuthenticated) {
      const token = localStorage.getItem(StorageKeys.Token);
      req = req.clone({
          setHeaders: {
              Authorization: `JWT ${token}`
          }
      });
    }

    return next.handle(req).pipe(
      finalize(() => updateLoader && this.loaderService.hide())
    );
  }
}
