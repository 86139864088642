import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth-module/auth.module').then(m => m.AuthModule)
  },
  // {
  //   path: '',
  //   component: BaseComponent,
  //   children: [
  //     {
  //       path: 'directory',
  //       loadChildren: () => import('./directory-module/directory.module').then(m => m.DirectoryModule),
  //       canLoad: [AuthGuard]
  //     },
  //     {
  //       path: 'profile',
  //       loadChildren: () => import('./profile-module/profile.module').then(m => m.ProfileModule),
  //       canLoad: [AuthGuard]
  //     },
  //     {
  //       path: 'factory',
  //       loadChildren: () => import('./factory-module/factory.module').then(m => m.FactoryModule),
  //       canLoad: [AuthGuard]
  //     },
  //     {
  //       path: 'transactions',
  //       loadChildren: () => import('./transactions-module/transactions.module').then(m => m.TransactionsModule),
  //       canLoad: [AuthGuard]
  //     },
  //     {
  //       path: 'wallet',
  //       loadChildren: () => import('./wallet-module/wallet.module').then(m => m.WalletModule),
  //       canLoad: [AuthGuard]
  //     }
  //   ]
  // },
  // /* TODO: DEVELOP GUARD */
  // {
  //   path: 'styleguide',
  //   component: StyleguideComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
