import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './components/base/base.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared-module/shared.module';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PayComponent } from './components/pay/pay.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MobileShellComponent } from './components/mobile-shell/mobile-shell.component';
import { DesktopShellComponent } from './components/desktop-shell/desktop-shell.component';



@NgModule({
  declarations: [
    BaseComponent,
    LoaderComponent,
    PayComponent,
    MobileShellComponent,
    DesktopShellComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatSidenavModule,
    RouterModule,
    MatToolbarModule,
    SharedModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatListModule,
    MatTabsModule
  ],
    exports: [
        LoaderComponent,
        PayComponent
    ]
})
export class CoreModule { }
