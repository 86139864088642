import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StyleguideComponent } from './components/styleguide/styleguide.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DebounceClickDirective } from './directives/debounce-click/debounce-click.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { ChartsModule } from 'ng2-charts';
import { TrimPipe } from './pipes/trim.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PurchaseOfferingComponent } from './components/purchase-offering/purchase-offering.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { StrokedButtonComponent } from './components/stroked-button/stroked-button.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { MatChipsModule } from '@angular/material/chips';
import { WalletOfferingReceiptDialogComponent } from './components/wallet-offering-receipt-dialog/wallet-offering-receipt-dialog.component';

@NgModule({
  declarations: [
    DebounceClickDirective,
    StyleguideComponent,
    TrimPipe,
    AreaChartComponent,
    PurchaseOfferingComponent,
    StrokedButtonComponent,
    SocialLinksComponent,
    WalletOfferingReceiptDialogComponent
  ],
  imports: [
    ChartsModule,
    FlexLayoutModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatChipsModule,

  ],
  exports: [
    ChartsModule,
    DebounceClickDirective,
    FlexLayoutModule,
    /* Material modules */
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    CdkTableModule,
    TrimPipe,
    AreaChartComponent,
    PurchaseOfferingComponent,
    StrokedButtonComponent,
    SocialLinksComponent,
    WalletOfferingReceiptDialogComponent
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000, verticalPosition: 'top'}}
  ]
})
export class SharedModule {
}
