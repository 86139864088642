import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpStatusCodes } from '../../enums/http-status-codes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth-service/auth.service';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  private readonly excludedUrls: string[] = [];

  constructor(private snackBar: MatSnackBar,
              private router: Router,
              private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap({error: (err: HttpErrorResponse) => this.globalHttpErrorParser(err, req, next)})
      );
  }

  private globalHttpErrorParser(errorResponse: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
    const code = errorResponse.status;
    /* TODO: check isExcluded */
    const isExcluded: boolean = this.excludedUrls.some((u) => errorResponse.url.includes(u));
    if ((code === HttpStatusCodes.BAD_REQUEST && !this.isNonFieldError(errorResponse)) || isExcluded) {
      return; // Ignore form's errors
    }

    let msg = '';
    if (code === HttpStatusCodes.UNAUTHORIZED) {
      msg = 'Your session has expired. Please login again to continue working.';
      // return this.authService.refreshToken().pipe(
      //   tap(() => next.handle(req))
      // ).subscribe();
      this.authService.logout();
    } else if (code === HttpStatusCodes.INTERNAL_SERVER_ERROR) {
      msg = 'Internal server error';
    } else if (code === HttpStatusCodes.NOT_FOUND) {
      msg = 'Something went wrong';
    } else {
      msg = this.formatMsg(errorResponse);
    }

    this.snackBar.open(msg || 'Something went wrong', 'OK', {
      duration: 10 * 1000,
      panelClass: 'error-bar'
    });
  }

  isNonFieldError(errorResponse: HttpErrorResponse): boolean {
    const error = errorResponse.error;
    return typeof error === 'object' && error.hasOwnProperty('non_field_errors');
  }

  private formatMsg(errorResponse: HttpErrorResponse): string {
    const error = errorResponse.error;
    if (typeof error === 'string') {
      return error;
    }

    if (typeof error === 'object' && error.hasOwnProperty('non_field_errors')) {
      if (typeof error.non_field_errors[0] === 'string') {
        return error.non_field_errors[0];
      }
      return error.non_field_errors[0].message || 'Something went wrong';
    }

    return '';

    // return _.chain(error)
    //   .mapValues((value, key) => `${key}: ${value}`)
    //   .values()
    //   .join('\n')
    //   .truncate({length: 100})
    //   .value()
    //   .replace('non_field_errors:', '');
  }
}
