import { Component } from '@angular/core';
import { IconsService } from './shared-module/services/icons-service/icons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private iconService: IconsService) {}
}
