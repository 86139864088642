import { Injectable } from '@angular/core';
import { ApiService } from '../../../core-module/services/api-service/api.service';
import { Observable, of } from 'rxjs';
import { BlockchainDetails } from '../../models/blockchain-details';
import { User } from '../../models/user';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private user: User;
  private details: BlockchainDetails;

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {
  }

  getUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }

    return this.apiService.get<User>('accounts/profile').pipe(
      tap(u => this.user = u)
    );
  }

  getBlockchainDetails(): Observable<BlockchainDetails> {
    if (this.details) {
      return of(this.details);
    }

    return this.apiService.get<BlockchainDetails>('accounts/blockchain').pipe(
      tap(d => this.details = d)
    );
  }

  updateProfile(data: any): Observable<any> {
    return this.apiService.patch('accounts/profile', data).pipe(
      tap(u => this.snackBar.open('Details are successfully updated', 'OK')),
      tap(u => this.user = u)
    );
  }

  clearCache(): void {
    this.user = null;
    this.details = null;
  }

  changePassword(body: { old_password: string, new_password1: string, new_password2: string }): Observable<any> {
    return this.apiService.post('accounts/change_password', body);
  }
}
